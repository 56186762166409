import React, { useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";

// @material-ui/icons
import CopyIcon from "@material-ui/icons/AddCircle";

// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import thisStyle from "assets/jss/material-kit-react/views/commonSections/sectionStyle.jsx";
import Muted from "components/Typography/Muted.jsx";
import Info from "components/Typography/Info.jsx";

class FaqMainContent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h2 className={classes.titleInfo}>Frequently Asked Questions</h2>
              <br />
              <div className={classes.textArea}>
                <Muted>
                  <Info><h4>What is staking?</h4></Info>
                  <p>Ada held on the Cardano network represents a stake in the network, with the size of the stake proportional to the amount of ada held. The ability to delegate or pledge a stake is fundamental to how Cardano works.</p>
                  <p>There are two ways an ada holder can earn rewards: by delegating their stake to a stake pool run by someone else, or running their own stake pool. The amount of stake delegated to a given stake pool is the primary way the Ouroboros protocol chooses who should add the next block to the blockchain, and receive a monetary reward for doing so.</p>
                  <p>The more stake is delegated to a stake pool (up to a certain point), the more likely it is to make the next block – and the rewards are shared between everyone who delegated their stake to that stake pool. </p>
                    
                  <Info><h4>What is stake pool performance?</h4></Info>
                  <p>The performance metric is an indicator of how well a stake pool is performing. Considering that the slot leader election process is private, it is only possible to estimate how often the stake pool should be elected based on the number of actually produced blocks. A pool can be nominated more often than expected based on its stake.. For example, if a pool only produces half the number of blocks that it was nominated for, its performance rating is 50%. This could happen because the pool has a poor network connection, or has been turned off by its operator.</p>
                  <p>Performance ratings make more sense over a longer period of time. If a pool has not yet been selected to produce a block in the current epoch, its performance rating will be 0%, even if it is likely to produce blocks later in the epoch. Performance ratings of over 100% are possible if a pool creates more blocks than it was nominated to produce.</p>
                  <p>In Daedalus and Yoroi, performance contributes to a stake pool’s ranking.  </p>

                  <Info><h4>What is stake pool saturation?</h4></Info>
                  <p>Saturation is a term used to indicate that a particular stake pool has more stake delegated to it than is ideal for the network, and once a pool reaches the point of saturation it will offer diminishing rewards. The saturation mechanism was designed to prevent centralization by encouraging delegators to delegate to different stake pools, and operators to set up alternative pools so that they can continue earning maximum rewards. Saturation, therefore, exists to preserve the interests of both ada holders delegating their stake and stake pool operators.</p>
                  <p>The goal is to avoid any single pool becoming too large – thereby disincentivizing delegation to other pools – and receiving a disproportionate amount of the rewards. The health of the network is partly determined by having a high number of active stake pools with a balanced amount of stake delegated to them. The more numerous and geographically diverse the network’s pools, the better. Each stake pool’s saturation percentage is shown within the Daedalus stake pool selection menu.</p>

                  <Info><h4>What is stake pool desirability?</h4></Info>
                  <p>Desirability measures how desirable a stake pool is to an ada holder seeking to delegate their stake. It is influenced by a number of factors – including a stake pool’s margin, fee, performance, the total reward available in the current epoch, and saturation percentage – and contributes to determining a stake pool’s ranking.</p>

                  <Info><h4>How are stake pools ranked?</h4></Info>
                  <p>Stake pools are ranked mainly through a combination of their desirability and performance, in addition to other factors.</p>

                  <Info><h4>How should I chose a stake pool?</h4></Info>
                  <p>Shelley Daedalus features a list of all participating stake pools directly via the UI, while Yoroi shows the same information via their Seiza blockchain explorer. These pools are ranked and color-coded to help stakeholders make the best decision about where to delegate their stake</p>

                  <Info><h4>How much money will I be able to make?</h4></Info>
                  <p>You can use the <a href="https://testnets.cardano.org/en/testnets/cardano/tools/staking-calculator/">rewards calculator</a> to get an idea of how much you will earn in rewards. It’s important to note that the calculator produces only reward estimates and shouldn’t be considered definitive or a guarantee of reward amounts. In the future, we will likely test different parameters that may affect reward margins. Amounts calculated are therefore subject to change, but represent a realistic and sensible level of return.</p>
                  
                </Muted>
              </div>
            </GridItem>
          </GridContainer>

          {/* <Link to={"/"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Home Page
            </Button>
          </Link> */}
        </div>
      </div>
    );
  }
}

export default withStyles(thisStyle)(FaqMainContent);
